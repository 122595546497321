export default defineNuxtRouteMiddleware((to, from) => {
  const user = useSupabaseUser();
  const profileCompleted = user.value?.user_metadata.profile_completed;
  const invited = user.value?.user_metadata.invited;

  if (user.value && !profileCompleted) {
    const targetPath = invited ? ROUTES.createCompanyMember : ROUTES.createCompanyOwner;
    if (to.path !== targetPath) return navigateTo(targetPath);
  }
});
